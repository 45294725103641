<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import mailSideBar from "@/components/email-components/mailSideBar";


/**
 * Email-read component
 */
export default {
  page: {
    title: "Read Email",
    meta: [{ name: "description" }]
  },
  components: {
    mailSideBar,
    Layout,
    PageHeader,
  },
  data() {
    return {
      email:null,
      title: "Read Email",
      items: [
        {
          text: "Email",
          href: "/email/inbox"
        },
        // {
        //   text: this.$route.params.text,
        //   href: this.$route.params.href
        // },
        {
          text: "Read Email",
          active: true
        }
      ],

    };
  },
  mounted() {

  },
  methods:{
    texttoHtml(text){
      return text.replace(/&lt;/g,'<')
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <mailSideBar/>
        <div class="email-rightbar mb-3">
          <div class="card">

            <div class="card-body">
              <div class="media mb-4">

                <div class="media-body">
                  <h5 class="font-size-14 my-1">{{this.$route.query.sms.toArray[0].phone_number}}</h5>
                  <small class="text-muted" ></small>
                </div>
              </div>

              <p v-html="texttoHtml(this.$route.query.sms.message)"></p>
              <hr />

            </div>
          </div>
        </div>
        <!-- card -->
      </div>
      <!-- end Col-9 -->
    </div>

  </Layout>
</template>
